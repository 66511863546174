<template>
  <v-container fluid class="unsubscribe-background">
    <v-container class="d-flex flex-column justify-space-between" style="height: calc(100vh - 58px);">
      <!-- <div v-if="$store.getters['user/account'].logo">
        <v-img max-width="100px" max-height="23px" contain :src="$store.getters['user/account'].logo" class="py-3" />
      </div> -->
      <div class="d-flex align-center">
        <img
          contain
          class="mr-6"
          width="30px"
          height="41px"
          src="@/assets/NavigationPane/logo_blue.png"
        >
        <img
          contain
          width="108px"
          height="32px"
          src="@/assets/NavigationPane/cloud.png"
        >
      </div>

      <div class="d-flex justify-center my-15">
        <div class="unsubscribe-content">
          <div v-if="verification_pending">
            <div class="field__header d-flex justify-center mb-5">
              Verifying Token
            </div>
            <div class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
          </div>

          <div v-else-if="error" class="d-flex flex-column justify-center align-center">
            <v-icon color="#de2828" size="30">
              mdi-close-circle-outline
            </v-icon>
            <div class="error-message mt-3">
              {{ error }}
            </div>
            <v-btn
              class="custom-button custom-button--blue mt-8"
              height="34px"
              depressed
              width="150px"
              @click="$router.push({name: 'Login'})"
            >
              Log In
            </v-btn>
          </div>

          <div v-else class="d-flex flex-column justify-center align-center">
            <div class="field__header mb-3">
              From Line Successfully Verified
            </div>
            <v-icon color="#2b84eb" size="25">
              mdi-check-circle
            </v-icon>
            <v-btn
              class="custom-button custom-button--blue mt-8"
              height="34px"
              depressed
              width="150px"
              @click="$router.push({name: 'Login'})"
            >
              Log In
            </v-btn>
          </div>
        </div>
      </div>

      <div class="login-footer">
        {{ `© Copyright 2015-${new Date(Date.now()).getFullYear()} Pulse Health - All Rights Reserved` }}
      </div>
    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "VerifyFromLineView",
  components: {
  },
  props: {
    accountId: {
      default: () => ''
    },
    token: {
      default: () => ''
    },
  },
  data() {
    return {
      verification_pending: false,
      error: null,
    };
  },
  computed: {
  },
  async created() {
    this.verification_pending = true
    try {
      await this.$rest.fromlines.verify_fromline_with_token(this.token, {
        headers: {
          'X-Account': this.accountId,
        },
      });
    } catch (error) {
      let error_message = null;
      let errors_list = "";
      if (error.response?.data?.errors?.length) {
        error.response.data.errors.forEach(item => {
          errors_list += (item.error + "\r \n");
        });
        error_message = errors_list;
      } else if (error.response?.data?.message) {
        error_message = error.response?.data?.message;
      }

      this.error = error_message || "Something went wrong";
    } finally {
      this.verification_pending = false
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.unsubscribe-content {
  max-width: 400px;
  max-height: 500px;
  padding: 36px 46px 30px;
}
.error-message {
  font-family: "Open Sans", sans-serif;
  color: #241c15;
  font-size: 13px;
}
</style>
